import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Button, Drawer, Modal } from "antd";
import { useReactToPrint } from "react-to-print";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function WCCallSheet({ data, setViewCallSheet, viewCallSheet }) {
  console.log("CSData-", data);
  const order = [
    "Pro",
    "Con",
    "Split",
    "Splitter",
    "Doubles",
    "Dribble",
    "Triple",
    "Trips",
    "Loco",
    "Cinco",
    "Army",
    "Navy",
    "Spread Army",
    "Spread Navy",
  ];

  const sortedData = data.sort((a, b) => {
    // Find the index of the keywords in the predefined order
    const indexA = order.findIndex((keyword) => a.playCall.includes(keyword));
    const indexB = order.findIndex((keyword) => b.playCall.includes(keyword));

    // If both playCalls have a keyword in the predefined order, sort by that order
    if (indexA !== -1 && indexB !== -1) {
      if (indexA !== indexB) {
        return indexA - indexB; // Sort by the predefined order
      }
      // If they belong to the same group, sort alphabetically within that group
      return a.playCall.localeCompare(b.playCall);
    }

    // If one has a keyword and the other doesn't, prioritize the one with the keyword
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither has a keyword from the predefined list, sort alphabetically
    return a.playCall.localeCompare(b.playCall);
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setScriptedPlays((prevPlays) => {
        return prevPlays.map((play) => {
          // Find the matching item in the new data based on the unique `key`
          const matchingData = data.find((item) => item.key === play.key);

          // If a match is found, update the properties
          if (matchingData) {
            return {
              ...play,
              playCall: matchingData.playCall,
              WristColor: matchingData.WristColor,
              WristNum: matchingData.WristNum,
            };
          }

          // If no match is found, return the play unchanged
          return play;
        });
      });
    }
  }, [data]);

  const printInfo = data;

  const componentRef = useRef();
  const scriptRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintScript = useReactToPrint({
    content: () => scriptRef.current,
  });

  const CSHeadersFront = [
    { label: "Green Zone", color: "bg-green-400", code: "Green Zone" },
    { label: "3rd & SHORT", color: "bg-yellow-200", code: "Short" },
  ];

  const CSHeadersBack = [
    { label: "High Red Zone", color: "bg-red-400", code: "HRZ" },
    { label: "3rd & MEDIUM", color: "bg-yellow-300", code: "Medium" },
    { label: "Low Red Zone", color: "bg-red-200", code: "LRZ" },
    { label: "3rd & LONG", color: "bg-yellow-400", code: "Long" },
    { label: "2 Minute", color: "bg-cyan-200", code: "2 Minute" },
    { label: "4 Minute", color: "bg-emerald-300", code: "4 Minute" },
    { label: "Shot Plays", color: "bg-orange-300", code: "Shot Plays" },
    { label: "Backed Up", color: "bg-blue-500", code: "Backed Up" },
    { label: "Goal Line", color: "bg-pink-300", code: "Goal Line" },
    { label: "Screens", color: "bg-indigo-300", code: "Screens" },
  ];

  const getPlayDetails = (play, side, position) => {
    const tagText = position === "above" ? "Run" : "Pass";
    return {
      playName:
        play.Tags.some((tag) => tag.text === tagText) &&
        play.Tags.some((tag) => tag.text === side)
          ? play.playCall
          : null,
    };
  };

  const filterPlays = (headerCode) => {
    return sortedData.filter(
      (play) =>
        play.Tags.some((tag) => tag.text === headerCode) &&
        (play.Tags.some((tag) => tag.text === "Run") ||
          play.Tags.some((tag) => tag.text === "Pass") ||
          play.Tags.some((tag) => tag.text === "Screens"))
    );
  };

  // Helper function to filter and separate plays
  const filterAndSeparatePlays = (headerCode) => {
    const plays = filterPlays(headerCode);

    const abovePlaysL = plays.filter(
      (play) => getPlayDetails(play, "L", "above").playName
    );

    const belowPlaysL = plays.filter(
      (play) => getPlayDetails(play, "L", "below").playName
    );

    const abovePlaysR = plays.filter(
      (play) => getPlayDetails(play, "R", "above").playName
    );

    const belowPlaysR = plays.filter(
      (play) => getPlayDetails(play, "R", "below").playName
    );
    return { abovePlaysL, belowPlaysL, abovePlaysR, belowPlaysR };
  };

  // Inside your component
  const [highlightedPlays, setHighlightedPlays] = useState([]); // Array of highlighted plays with their highlight level

  // Function to toggle the highlight level of a play
  const toggleHighlight = (playCall) => {
    setHighlightedPlays((prev) => {
      const play = prev.find((p) => p.playCall === playCall);

      if (play) {
        // If play is already highlighted, cycle through the highlight levels (0 -> 1 -> 2 -> 3)
        const newLevel = (play.level + 1) % 4; // Cycle through 0, 1, 2, 3
        if (newLevel === 0) {
          // If back to 0, remove the play from highlightedPlays
          return prev.filter((p) => p.playCall !== playCall);
        } else {
          // Otherwise, update the play's level
          return prev.map((p) =>
            p.playCall === playCall ? { ...p, level: newLevel } : p
          );
        }
      } else {
        // If play is not highlighted, add it with level 1 (first highlight)
        return [...prev, { playCall, level: 1 }];
      }
    });
  };

  const [showScripting, setShowScripting] = useState(false);
  const [scriptedPlays, setScriptedPlays] = useState([]);

  const renderPlays = (plays, side, position) => {
    return plays.map((play, playIndex) => {
      const { playName } = getPlayDetails(play, side, position);
      const isEven = playIndex % 2 === 0;

      // Find the play's highlight level (0 if not found)
      const playHighlight = highlightedPlays.find(
        (p) => p.playCall === play.playCall
      );
      const highlightLevel = playHighlight ? playHighlight.level : 0;

      // Apply different styles based on highlight level
      const highlightStyles = {
        0: "", // No highlight
        1: "outline outline outline-yellow-500 -outline-offset-1", // First click - border
        2: "text-red-400", // Second click - text color
        3: "bg-yellow-300 bg-opacity-60", // Third click - background color
      };

      return playName ? (
        <div
          key={playIndex}
          className={`flex flex-col out w-full h-fit min-h-full cursor-pointer ${
            position === "below" && playIndex === 0
              ? "border-t border-slate-500 border-dotted mt-4"
              : ""
          } text-xs ${isEven ? "bg-slate-50" : ""} ${
            highlightStyles[highlightLevel]
          }`}
          onClick={() => {
            if (!showScripting) {
              toggleHighlight(play.playCall);
            } else {
              addToScript(play);
            }
          }} // Toggle highlight on click
        >
          <div className="flex w-full overflow-hidden">
            <div className="flex h-fit my-auto w-[2rem] border-r mr-1 pl-1 font-semibold">
              <div
                className={`${play.WristColor} rounded-full h-2 w-2 mt-1 mr-1`}
              ></div>
              <div id="playColor&Num">{play.WristNum}</div>
            </div>
            <div id="playName">{playName}</div>
          </div>
        </div>
      ) : null;
    });
  };

  const addToScript = (play) => {
    setScriptedPlays((prev) => {
      // Clone the play object and generate a unique key for it
      const newPlay = {
        ...play,
        key: `${play.key}-added-${Date.now()}-${Math.random()}`, // Create a unique key for the new play
      };

      // Add the play with the unique key to the script
      return [...prev, newPlay];
    });
    console.log("scripted plays", scriptedPlays);
  };

  // Function to add a new section title divider
  const addSectionDivider = () => {
    setScriptedPlays((prev, index) => [
      ...prev,
      {
        key: "Section " + index,
        title: `New Section ${prev.filter((p) => !p.playCall).length + 1}`,
      },
    ]);
  };

  // Function to remove a play or divider from the list
  const removeFromScript = (index) => {
    setScriptedPlays((prev) => prev.filter((_, i) => i !== index));
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  // Handle the drag end event to reorder the items in the list
  const handleDragEnd = (event) => {
    const { active, over } = event;

    // Only update state if the items have moved to a different position
    if (active.key !== over.id) {
      setScriptedPlays((prev) => {
        const oldIndex = prev.findIndex((item) => item.key === active.id);
        const newIndex = prev.findIndex((item) => item.key === over.id);
        return arrayMove(prev, oldIndex, newIndex);
      });
    }
  };

  const [editingTitle, setEditingTitle] = useState({ index: null, value: "" });

  // Function to confirm title change on blur or enter
  const confirmTitleChange = () => {
    if (editingTitle.index !== null) {
      setScriptedPlays((prev) => {
        const newPlays = [...prev];
        newPlays[editingTitle.index].title = editingTitle.value.trim(); // Update the title
        return newPlays;
      });
      setEditingTitle({ index: null, value: "" }); // Clear the editing state
    }
  };

  const [scriptExpanded, setScriptExpanded] = useState(false);

  // Function to duplicate a play and insert it after the current index
  const duplicatePlay = (index) => {
    setScriptedPlays((prev) => {
      const newPlays = [...prev];
      const playToDuplicate = { ...newPlays[index] };

      // Generate a new unique key for the duplicated play
      playToDuplicate.key = `${playToDuplicate.key}-copy-${Date.now()}`;

      // Insert the cloned play after the current play
      newPlays.splice(index + 1, 0, playToDuplicate);

      return newPlays;
    });
  };

  const duplicateSection = (dividerIndex) => {
    setScriptedPlays((prev) => {
      const newPlays = [...prev];

      // Find the range of the section to duplicate (from the divider to the next divider or end of array)
      let sectionEndIndex = dividerIndex + 1;
      while (
        sectionEndIndex < newPlays.length &&
        newPlays[sectionEndIndex].playCall
      ) {
        sectionEndIndex++;
      }

      // Extract and copy the section (divider + plays)
      const sectionToDuplicate = newPlays.slice(dividerIndex, sectionEndIndex);

      // Assign unique keys for each item in the duplicated section
      const duplicatedSection = sectionToDuplicate.map((item) => ({
        ...item,
        key: `${item.key}-copy-${Date.now()}-${Math.random()}`, // Generate a unique key for each item
        title: item.title ? `${item.title} (Copy)` : item.title, // Update title if it's a divider
      }));

      // Insert the duplicated section after the original section
      newPlays.splice(sectionEndIndex, 0, ...duplicatedSection);
      return newPlays;
    });
  };

  // Sortable Item Component
  const SortableItem = ({
    id,
    item,
    index,
    playNumber,
    editingTitle,
    setEditingTitle,
    removeFromScript,
    duplicatePlay,
    duplicateSection,
  }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    if (item.playCall) {
      // Define the array containing the target values
      const hashValues = ["L", "M", "R"];

      const hashes = hashValues.filter((value) =>
        item.Tags.some((tag) => tag.text === value)
      );

      return (
        <li
          ref={setNodeRef}
          style={style}
          className={`${
            index % 2 === 0 ? "bg-slate-100" : ""
          } h-fit flex cursor-pointer`}
        >
          {/* Display play number */}
          <div className="w-3 mr-3 ml-1 py-1 font-semibold">{playNumber}. </div>
          <div className="w-16 mx-6 py-1 font-semibold overflow-clip">
            {hashes.join(" ")}{" "}
          </div>
          <div className="flex w-96 py-1 overflow-hidden hover:font-semibold">
            <div className="flex h-fit w-10 my-auto border-r mr-1 pl-1 font-semibold">
              <div
                className={`${item.WristColor} rounded-full h-2 w-2 mt-[6px] mr-1`}
              ></div>
              <div className="pr-1">{item.WristNum}</div>
            </div>
            <div>{item.playCall}</div>
          </div>
          {/* Drag Handle Buttons */}
          <div
            {...attributes}
            {...listeners}
            className="flex flex-col gap-1 justify-center no-print hover:opacity-100 opacity-10 hover:font-bold hover:scale-105 cursor-move"
          >
            <div className="border-x-8 border-x-transparent border-b-[7px] border-b-black cursor-move"></div>
            <button className="hover:scale-105 border-x-8 border-x-transparent border-b-[7px] border-b-black rotate-180 cursor-move"></button>
          </div>
          {/* Duplicate Button */}
          <div
            className="ml-2 p-1 rounded-md text-xs no-print opacity-20 hover:opacity-100"
            onClick={() => duplicatePlay(index)}
          >
            Copy
          </div>
          {/* Remove Button */}
          <button
            className="ml-2 p-1 rounded-md text-xs no-print hover:text-red-400 opacity-20 hover:opacity-100"
            onClick={() => removeFromScript(index)}
          >
            X
          </button>
        </li>
      );
    } else {
      return (
        <li
          ref={setNodeRef}
          style={style}
          className="w-full bg-gray-300 h-fit flex items-center cursor-pointer py-2 px-2 mb-1 rounded-md"
        >
          <div
            className="w-96 mr-5"
            onClick={() => setEditingTitle({ index, value: item.title })}
          >
            <input
              type="text"
              value={
                editingTitle.index === index ? editingTitle.value : item.title
              }
              onChange={(e) =>
                setEditingTitle({ index, value: e.target.value })
              }
              onBlur={confirmTitleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") confirmTitleChange();
              }}
              className={`w-full bg-transparent outline-none text-lg font-bold pl-2 ${
                editingTitle.index === index ? "border p-1" : "cursor-pointer"
              }`}
              style={{
                display: editingTitle.index === index ? "inline" : "none",
              }}
              autoFocus={editingTitle.index === index}
            />
            {editingTitle.index !== index && (
              <span className="w-full p-1 font-bold text-lg pl-2">
                {item.title}
              </span>
            )}
          </div>
          <div
            {...attributes}
            {...listeners}
            className="flex flex-col gap-1 justify-center no-print hover:opacity-100 opacity-10 hover:font-bold hover:scale-105 cursor-move"
          >
            <div className="border-x-8 border-x-transparent border-b-[7px] border-b-black"></div>
            <button className="hover:scale-105 border-x-8 border-x-transparent border-b-[7px] border-b-black rotate-180"></button>
          </div>
          {/* Duplicate Section Button */}
          <button
            className="ml-2 p-1 rounded-md text-xs no-print hover:text-white"
            onClick={() => duplicateSection(index)}
          >
            Copy Section
          </button>
          {/* Remove Button */}
          <button
            className="ml-4 p-1 rounded-md text-xs no-print hover:text-red-400"
            onClick={() => removeFromScript(index)}
          >
            X
          </button>
        </li>
      );
    }
  };

  return (
    <Modal
      className="h-fit pt-[10%] sm:pt-[5%] md:pt-3 pb-2"
      centered
      width={1300}
      open={viewCallSheet}
      onOk={() => setViewCallSheet()}
      onCancel={() => setViewCallSheet()}
      destroyOnClose={false}
      title={[
        <div className="w-full flex justify-center">
          <Button
            className="w-1/2 min-w-fit mx-auto font-semibold"
            onClick={() => setShowScripting(!showScripting)}
          >
            Script
          </Button>
        </div>,
      ]}
      footer={[
        <motion.button
          key="printButton"
          className="p-2 m-1 mb-2 h-fit hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm disabled:opacity-50"
          whileHover={{ scale: 0.9 }}
          whileTap={{ scale: 1.1 }}
          onClick={handlePrint}
        >
          Print
        </motion.button>,
      ]}
    >
      <Drawer
        title="Script Maker"
        placement="bottom"
        height={scriptExpanded ? "92vh" : "50vh"}
        mask={false}
        onClose={() => setShowScripting(false)}
        extra={
          <>
            <Button onClick={() => setScriptExpanded(!scriptExpanded)}>
              Expand
            </Button>
            <motion.button
              key="printButton"
              className="p-2 m-1 mb-2 h-fit hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm disabled:opacity-50"
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              onClick={handlePrintScript}
            >
              Print
            </motion.button>
          </>
        }
        open={showScripting}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <div ref={scriptRef} className="h-fit w-full pl-3">
            <div className="text-lg font-bold mb-2">Script Title</div>
            <SortableContext
              items={scriptedPlays.map((item) => item.key)}
              strategy={verticalListSortingStrategy}
            >
              <ol>
                {scriptedPlays.map((item, index) => {
                  const isEven = index % 2 === 0;
                  let playNumber = 1;

                  // Calculate play numbers
                  for (let i = 0; i < index; i++) {
                    if (!scriptedPlays[i].playCall) {
                      playNumber = 1;
                    } else {
                      playNumber++;
                    }
                  }

                  return (
                    <SortableItem
                      key={item.key}
                      id={item.key}
                      item={item}
                      index={index}
                      playNumber={playNumber}
                      editingTitle={editingTitle}
                      setEditingTitle={setEditingTitle}
                      removeFromScript={removeFromScript}
                      duplicatePlay={duplicatePlay}
                      duplicateSection={duplicateSection}
                    />
                  );
                })}
              </ol>
            </SortableContext>
          </div>
        </DndContext>
        <button
          onClick={addSectionDivider}
          className="mt-4 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add Period
        </button>
      </Drawer>
      <div
        ref={componentRef}
        className={`relative px-[3%] mt-1 flex flex-col h-fit min-h-fit w-full mx-auto py-4 bg-white rounded-md`}
      >
        {new Date().toLocaleString() + ""}
        <div className="w-full bg-slate-800 text-white text-xl font-bold text-center rounded-t-md">
          {printInfo?.label}
        </div>
        <div className="flex h-fit min-h-[60vh] w-full gap-5 mt-3 mx-auto border-2 border-black">
          <div className="grid grid-cols-2 w-full h-fit min-h-[60vh]">
            {CSHeadersFront.map((header, index) => {
              const { abovePlaysL, abovePlaysR, belowPlaysL, belowPlaysR } =
                filterAndSeparatePlays(header.code);
              {
                if (
                  abovePlaysL.length +
                    abovePlaysR.length +
                    belowPlaysL.length +
                    belowPlaysR.length >
                  0
                ) {
                  return (
                    <div
                      key={index}
                      className="w-full h-fit min-h-full pb-1 content-start grid grid-cols-2 border-r"
                    >
                      <div
                        id={`header-${index}`}
                        className={`h-fit w-full col-span-2 ${header.color} text-center text-lg font-bold`}
                      >
                        {header.label}
                      </div>
                      <div className="h-fit border-b border-r text-center font-semibold underline">
                        Left
                      </div>
                      <div className="h-fit border-b text-center font-semibold underline">
                        Right
                      </div>
                      <div
                        id={`left-${index}`}
                        className="flex flex-col w-full h-fit min-h-full border-r"
                      >
                        {renderPlays(abovePlaysL, "L", "above")}
                        {renderPlays(belowPlaysL, "L", "below")}
                      </div>
                      <div
                        id={`right-${index}`}
                        className="flex flex-col w-full h-fit min-h-full"
                      >
                        {renderPlays(abovePlaysR, "R", "above")}
                        {renderPlays(belowPlaysR, "R", "below")}
                      </div>
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>

        <div
          className={`flex ${
            showScripting ? "mb-96" : ""
          } h-fit min-h-[60vh] mt-12 w-full gap-5 mx-auto border-2 border-black break-before-page`}
        >
          <div className="grid grid-cols-2 w-full h-fit min-h-[60vh]">
            {CSHeadersBack.map((header, index) => {
              const { abovePlaysL, abovePlaysR, belowPlaysL, belowPlaysR } =
                filterAndSeparatePlays(header.code);
              {
                if (
                  abovePlaysL.length +
                    abovePlaysR.length +
                    belowPlaysL.length +
                    belowPlaysR.length >
                  0
                ) {
                  return (
                    <div
                      key={index}
                      className="w-full h-fit min-h-full pb-1 content-start grid grid-cols-2 border-r"
                    >
                      <div
                        id={`header-${index}`}
                        className={`h-fit w-full col-span-2 ${header.color} text-center text-lg font-bold`}
                      >
                        {header.label}
                      </div>
                      <div className="h-fit border-b border-r text-center font-semibold underline">
                        Left
                      </div>
                      <div className="h-fit border-b text-center font-semibold underline">
                        Right
                      </div>
                      <div
                        id={`left-${index}`}
                        className="flex flex-col w-full h-fit min-h-full border-r"
                      >
                        {renderPlays(abovePlaysL, "L", "above")}
                        {renderPlays(belowPlaysL, "L", "below")}
                      </div>
                      <div
                        id={`right-${index}`}
                        className="flex flex-col w-full h-fit min-h-full"
                      >
                        {renderPlays(abovePlaysR, "R", "above")}
                        {renderPlays(belowPlaysR, "R", "below")}
                      </div>
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}
