import React, {
  useState,
  useCallback,
  useRef,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import {
  Table,
  Button,
  Popconfirm,
  Cascader,
  Input,
  Form,
  Select,
  Tag,
} from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { GrDocumentExcel, GrEdit } from "react-icons/gr";
import { AnimatePresence, motion } from "framer-motion";
import { db } from "../Firebase-config";
import {
  doc,
  writeBatch,
  setDoc,
  where,
  documentId,
  collection,
  query,
  onSnapshot,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import PlayerCascader from "../components/PlayerCascader";
import DataTitleCascader from "../components/DataTitleCascader";
import GSDataTitleCascader from "../components/GSDataTitleCascader";
import MyTeamDataTitleCascader from "../components/MyTeamDataTitleCascader";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { useMediaQuery } from "react-responsive";
import { defData } from "../PositionColumns/DefensiveStatColumns";
import {
  qbData,
  olData,
  teData,
  rbData,
  wrData,
} from "../PositionColumns/OffensiveStatColumns";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { FaMinus, FaPlus } from "react-icons/fa";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";

export function GradeSheet() {
  const [colDefs, setColDefs] = useState([]);
  const [dataExcel, setData] = useState([]);
  const [displayColDefs, setDisplayColDefs] = useState([]);
  const [displayDataExcel, setDisplayDataExcel] = useState([]);
  const [playerCols, setPlayerCols] = useState(null);
  const [playerData, setPlayerData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [colValue, setColValue] = useState([]);
  const [playNumber, setPlayNumber] = useState([1]);
  const [fetchedGradeTitle, setFetchedGradeTitle] = useState([1]);
  const [columnFilterError, setColumnFilterError] = useState("");
  const [showEdit, toggleShowEdit] = useState(false);
  const [showUpload, toggleShowUpload] = useState(false);
  const [showAddToMyTeam, setShowAddToMyTeam] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  //Parse Excel and Set Play Table Data
  const convertToJson = (headers, data) => {
    const rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, key) => {
        rowData[headers[key]] = element;
      });
      rows.push(rowData);
    });
    return rows;
  };

  const importExcel = async (e) => {
    var XLSX = require("xlsx");

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.readFile(data);

    console.log(workbook);

    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];

    const fileData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    console.log(fileData);

    const headers = fileData[0];
    console.log(headers);
    const heads = headers.map((head) => ({
      title: head,
      key: head,
      dataIndex: head,
    }));

    setColDefs(heads);
    console.log(heads);

    fileData.splice(0, 1);

    openNotificationWithIcon(
      "success",
      "Upload Successful",
      "Select a Label, and Save to the Database to edit",
      "top"
    );

    const rawJSON = convertToJson(headers, fileData);
    for (let i = 0; i < rawJSON.length; i++) {
      if (!rawJSON[i].hasOwnProperty("Play")) {
        rawJSON[i] = { Play: `Play ${i + 1}`, ...rawJSON[i] };
      }
    }

    setData(rawJSON);
  };

  const [gradeTitle, setGradeTitle] = useState([]);
  const [gradeLabel, setGradeLabel] = useState([]);

  const updateGradeTitle = (title) => {
    setGradeTitle(title);
    setGradeLabel(title.slice(-1));
    console.log(gradeLabel);
  };

  const updateFetchedGradeTitle = (title) => {
    if (title != null) {
      setFetchedGradeTitle(title);
      setGradeTitle(title);
      setGradeLabel(title.slice(-1));
    }
  };

  // Player Table Actions
  const handleDelete = useCallback((key) => {
    setPlayerData((playerData) =>
      playerData.filter((row) => row["name-play"] !== key)
    );
  }, []);

  const actionButtons = {
    key: "delete",
    title: "Delete",
    dataIndex: "delete",
    width: 75,
    render: (_, data) => (
      <Popconfirm
        title="Sure to delete?"
        okType="danger"
        onConfirm={() => handleDelete(data["name-play"])}
      >
        <DeleteOutlined className="scale-125 ml-4" />
      </Popconfirm>
    ),
  };

  const colOptions = [
    {
      key: "1",
      label: "Defense",
      value: "defense",
    },
    {
      label: "Offense",
      value: "offense",
      children: [
        {
          label: "QB",
          value: "QB",
        },
        {
          label: "OL",
          value: "OL",
        },
        {
          label: "TE",
          value: "TE",
        },
        {
          label: "RB",
          value: "RB",
        },
        {
          label: "WR",
          value: "WR",
        },
      ],
    },
  ];

  // Use useRef for actionButtons since it doesn't change
  const actionButtonsRef = useRef(actionButtons);

  // Create a dictionary to map title[1] values to data arrays
  const dataDictionary = {
    QB: qbData,
    OL: olData,
    TE: teData,
    RB: rbData,
    WR: wrData,
  };

  const columnTitle = (title) => {
    if (title[0] === "defense") {
      const defColumns = defData.map(mapColumns);
      setPlayerCols([...defColumns, actionButtonsRef.current]);
    } else if (dataDictionary[title[1]]) {
      const data = dataDictionary[title[1]];
      const playerColumns = data.map(mapColumns);
      setPlayerCols([...playerColumns, actionButtonsRef.current]);
    } else if (title === 0) {
      setPlayerCols(null);
    }
  };

  const memoizedPlayerCols = useMemo(() => playerCols, [playerCols]);

  const [playerSelected, setPlayerSelected] = useState([]);
  const [profileData, setProfileData] = useState([]);

  //probably have to for loop through the array to get all players
  const setPlayerButtons = (props) => {
    const selectedPlayersArray = props.e;
    const selectedPlayersProfileArray = props.profileData;

    if (selectedPlayersArray.length > 0) {
      setPlayerSelected(selectedPlayersArray);
      setProfileData(selectedPlayersProfileArray);
    } else {
      setPlayerSelected([]);
    }
    console.log("playerValue: ", selectedPlayersArray);
    console.log("profileData: ", selectedPlayersProfileArray);
  };

  const handleAddSelected = () => {
    if (playerSelected.length > 0) {
      try {
        const newData = playerSelected.map((person) => {
          const namePlay = `${person[2]} ${playNumber} ${gradeLabel}`;
          const fullName = person[2];
          const position = person[1];
          const play = playNumber;
          const gameId = gradeTitle.join();
          const playerProfile = profileData.find(
            (profile) => profile["full-name"] === fullName
          );
          const playerId = playerProfile.id;

          // Check if a player object with the same 'name-play' value already exists
          const existingPlayer = playerData.find(
            (player) => player["name-play"] === namePlay
          );
          if (existingPlayer) {
            // Skip adding the player if it already exists
            return null;
          } else {
            // Add the player to the new data array
            return {
              "name-play": namePlay,
              "full-name": fullName,
              position,
              assignment: "+",
              Play: play,
              gameID: gameId,
              playerId: playerId,
            };
          }
        });

        // Filter out any null values (skipped players) and add the new data to playerData
        setPlayerData([
          ...playerData,
          ...newData.filter((player) => player !== null),
        ]);
      } catch {
        openNotificationWithIcon(
          "warning",
          "Select Players",
          "Issue with selected players.",
          "topRight"
        );
      }
    } else {
      openNotificationWithIcon(
        "warning",
        "Select Players!",
        "Make sure players are selected in the player cascader in order to add them.",
        "topRight"
      );
    }
  };

  const { Option } = Select;

  const EditableContext = React.createContext(null);

  const EditableRow = React.memo((props) => {
    const [form] = Form.useForm();
    const [editingIndex, setEditingIndex] = useState(null);
    const toggleEdit = useCallback(
      (dataIndex) => {
        setEditingIndex(dataIndex === editingIndex ? null : dataIndex);
      },
      [editingIndex]
    );

    const contextValue = useMemo(
      () => ({
        form,
        editingIndex,
        toggleEdit,
      }),
      [form, editingIndex, toggleEdit]
    );

    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={contextValue}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  });

  const EditableCell = React.memo(
    ({
      title,
      editable,
      children,
      dataIndex,
      record,
      handleSave,
      ...restProps
    }) => {
      const { form, editingIndex, toggleEdit } = useContext(EditableContext);
      const [isOpen, setIsOpen] = useState(false);
      const inputRef = useRef(null);

      useEffect(() => {
        if (editingIndex === dataIndex) {
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
          setIsOpen(true);
          inputRef.current.focus();
        } else {
          setIsOpen(false);
        }
      }, [editingIndex, dataIndex, record, form]);

      const save = useCallback(async () => {
        try {
          const values = await form.validateFields();
          const updatedRecord = { ...record, ...values };
          handleSave(playerData, {
            ...updatedRecord,
            [dataIndex]:
              values[dataIndex] !== undefined
                ? values[dataIndex]
                : dataIndex === "numericValue"
                ? 0
                : null,
          });
        } catch (errInfo) {
          console.log("Save failed:", errInfo);
        } finally {
          setIsOpen(false);
        }
      }, [dataIndex, form, handleSave, record]);

      const handleClick = () => {
        if (editable) {
          toggleEdit(dataIndex);
        }
      };

      let inputNode;
      if (dataIndex === "coach-comments" || dataIndex === "position") {
        inputNode = (
          <Input
            style={{ minWidth: 50, margin: 0 }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        );
      } else if (dataIndex === "assignment") {
        inputNode = (
          <Select
            ref={inputRef}
            open={isOpen}
            onPressEnter={save}
            onBlur={save}
            onChange={() => {
              setIsOpen(!isOpen);
              save();
            }}
          >
            <Option value={"+"}>
              <Tag className="mt-1 p-1" color={"green"} key={"+"}>
                <FaPlus className="mx-auto" />
              </Tag>
            </Option>
            <Option value={"-"}>
              <Tag className="mt-1 p-1" color={"volcano"} key={"-"}>
                <FaMinus className="mx-auto" />
              </Tag>
            </Option>
          </Select>
        );
      } else {
        inputNode = (
          <Select
            ref={inputRef}
            open={isOpen}
            onPressEnter={save}
            onBlur={save}
            defaultValue={dataIndex === "sack" ? null : 0}
            onChange={() => {
              setIsOpen(!isOpen);
              save();
            }}
          >
            {dataIndex === "sack" && <Option value={0.5}>.5</Option>}
            <Option value={null}>0</Option>
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
          </Select>
        );
      }

      const childNode =
        editingIndex === dataIndex ? (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[{ required: false, message: `${title} is required.` }]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 20 }}
            onClick={handleClick}
          >
            {children}
          </div>
        );

      return <td {...restProps}>{childNode}</td>;
    }
  );

  // Assuming this code is in the same component where `handleSave` is defined.

  const handleSave = (playerData, row) => {
    // Find the index of the row to update
    const index = playerData.findIndex(
      (item) => item["name-play"] === row["name-play"]
    );

    // If the row exists in the playerData array, update it
    if (index !== -1) {
      const updatedPlayerData = [...playerData];
      updatedPlayerData[index] = { ...updatedPlayerData[index], ...row };
      setPlayerData(updatedPlayerData);
    } else {
      // If the row doesn't exist, add it to the playerData array
      setPlayerData([...playerData, row]);
    }
  };

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    [playerData]
  );

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    console.log(newCol);
    return newCol;
  };

  useEffect(() => {
    if (!playerSelected || playerSelected.length === 0) {
      console.log("playerSelected is an empty array.");
      setDisplayData([]);
      return;
    }

    // Create a set of selected names
    const selectedNames = new Set(playerSelected.flatMap((arr) => arr[2]));

    // Filter playerData based on selected names and play number
    const playFilteredPlayerData = playerData.filter(
      (player) =>
        selectedNames.has(player["full-name"]) ||
        selectedNames.has(player.playerId)
    );

    setDisplayData(playFilteredPlayerData);
  }, [playerSelected, playerData, playNumber]);

  const getData = async () => {
    setIsStale(true); // Mark data as stale while re-fetching
    const collectionPath = collection(db, "Teams", selectedPath, "EventData");
    const gradeLabel = gradeTitle ? gradeTitle : [fetchedGradeTitle];
    const documentIds = [gradeLabel + " PlayerData", gradeLabel + " PlayData"];

    const q = query(collectionPath, where("__name__", "in", documentIds));
    const dataPromise = new Promise((resolve) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id === gradeLabel + " PlayData") {
            setDisplayColDefs(data.colDefs);
            setDisplayDataExcel(data.dataExcel);
          } else if (doc.id === gradeLabel + " PlayerData") {
            setPlayerData(data.playerData || data.myTeamPlayerData);
          }
        });
        setIsStale(false); // Data is now up-to-date
        resolve(); // Mark the promise as resolved once the data is fetched
      });

      // Cleanup function to unsubscribe the listener
      return () => {
        unsubscribe();
      };
    });

    setDataFetchedPromise(dataPromise); // Store the promise to use in updateFirestore
  };

  // Auto-reload on window focus or visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        console.log("Document is visible again, re-fetching data...");
        getData(); // Re-fetch the data when the tab becomes active
      } else {
        setIsStale(true); // Data may be stale when visibility is hidden
      }
    };

    const handleWindowFocus = () => {
      console.log("Window regained focus, re-fetching data...");
      getData(); // Re-fetch the data when the window regains focus
    };

    // Add event listeners for visibility change and window focus
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleWindowFocus);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  const memoizedDisplayColDefs = useMemo(
    () => displayColDefs,
    [displayColDefs]
  );

  const clearData = () => {
    setDisplayDataExcel([]);
    setDisplayColDefs([]);
    setPlayerData([]);
  };

  useEffect(() => {
    clearData();
  }, [selectedPath]);

  const savePlayData = async () => {
    updateFetchedGradeTitle(gradeTitle);
    console.log(gradeTitle);
    try {
      await handlePlayDataUpload(); // Wait for handlePlayDataUpload() to finish
      getData(); // Call getData() after handlePlayDataUpload() is finished
    } catch (error) {
      console.error("Error during handlePlayDataUpload():", error);
      //***********ERROR MESSAGE */
    }
  };

  const handlePlayDataUpload = async () => {
    const userID = currentUser.uid;
    if (gradeTitle.length !== 0) {
      for (let i = 0; i < dataExcel.length; i++) {
        // add the gameID key-value pair to the current object
        dataExcel[i].gameID = gradeTitle.join();
      }
      try {
        const batch = writeBatch(db);
        batch.set(
          doc(
            db,
            "Teams",
            selectedPath,
            "EventData",
            gradeTitle + " PlayerData"
          ),
          { playerData, gradeLabel, userID, createdAt: serverTimestamp() }
        );
        batch.set(
          doc(db, "Teams", selectedPath, "EventData", gradeTitle + " PlayData"),
          { colDefs, dataExcel, userID, createdAt: serverTimestamp() }
        );
        await batch.commit();
        openNotificationWithIcon(
          "success",
          "Saved",
          "The event has been saved to your teams storage",
          "topLeft"
        );
      } catch (error) {
        alert(error);
      }
    } else {
      alert("Select a Title before saving!");
    }
  };

  const [showTotals, setShowTotals] = useState(false);

  const PlayerTotals = () => {
    const groupedData = {};

    displayData.forEach((player) => {
      const key = player.playerId || player["full-name"];
      if (!groupedData[key]) {
        groupedData[key] = {
          full_name: player["full-name"],
          totalPluses: 0,
          totalMinuses: 0,
          totalAssignment: 0,
        };
      }

      if (player.assignment === "+") {
        groupedData[key].totalPluses += 1; // Increment by 1 for each '+'
        groupedData[key].totalAssignment += 1; // Increment Total
      } else if (player.assignment === "-") {
        groupedData[key].totalMinuses += 1; // Increment by 1 for each '-'
        groupedData[key].totalAssignment += 1; // Increment Total
      }
    });

    return (
      <div>
        <button
          onClick={() => setShowTotals(!showTotals)}
          className="flex text-sm mt-1"
        >
          <div
            className={`transition-all mr-1 ${showTotals ? `rotate-180` : ``}`}
          >
            v
          </div>{" "}
          Current Player Totals
        </button>
        {showTotals && (
          <div className="flex flex-wrap w-full gap-2">
            {Object.values(groupedData).map((group) => {
              const totalPercent = Math.round(
                (group.totalPluses / group.totalAssignment) * 100
              );

              return (
                <div className="mt-2" key={group.full_name}>
                  <p className="flex flex-col bg-slate-100 p-2 rounded-lg">
                    <span className="font-semibold mx-2">
                      {group.full_name}
                    </span>
                    <div className="font-semibold border-b border-black w-full"></div>
                    <span className="mx-auto w-fit">
                      {group.totalPluses} / {group.totalAssignment} ={" "}
                      {totalPercent}%
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const [firebaseSaved, setFirebaseSaved] = useState("Saved");

  const [isStale, setIsStale] = useState(false);
  const [dataFetchedPromise, setDataFetchedPromise] = useState(
    Promise.resolve()
  );

  // useEffect to automatically update Firestore when playerData changes
  useEffect(() => {
    const updateFirestore = async () => {
      if (isStale) {
        console.log(
          "Data is stale, waiting for the latest data before saving..."
        );
        await dataFetchedPromise; // Wait for data to be up-to-date
      }

      if (playerData.length > 0) {
        const collectionPath = collection(
          db,
          "Teams",
          selectedPath,
          "EventData"
        );
        const gradeLabel = [fetchedGradeTitle];
        const playerDataDocId = gradeLabel + " PlayerData";

        // Save changes to Firestore
        const docRef = doc(collectionPath, playerDataDocId);
        setFirebaseSaved("Saving...");
        try {
          await updateDoc(docRef, { playerData });
          setFirebaseSaved("Saved");
          console.log("PlayerData updated successfully in Firestore");
        } catch (error) {
          setFirebaseSaved(
            "WARNING, NO FURTHER CHANGES WILL BE SAVED! Close the event and re-open to continue.",
            error.message,
            "top"
          );
          console.error("Error updating PlayerData in Firestore: ", error);
          openNotificationWithIcon(
            "error",
            "Error Updating Data",
            "Close the event and re-open to continue saving",
            "top"
          );
        }
      }
    };

    updateFirestore();
  }, [playerData]);

  const [myTeamGradeTitle, setMyTeamGradeTitle] = useState();
  const [myTeamPlayers, setMyTeamPlayers] = useState();
  const [showMyTeamModal, setShowMyTeamModal] = useState(false);

  useEffect(() => {
    if (selectedPath !== currentUser.uid) {
      setShowMyTeamModal(true);
    } else {
      setShowMyTeamModal(false);
    }
  }, [selectedPath]);

  const handleMyTeamPlayers = (players) => {
    const copyPlayerData = [...playerData];
    const myTeamFilteredPlayers = players.map((player) => player[2]);
    const myTeamPlayerData = copyPlayerData.filter((player) =>
      myTeamFilteredPlayers.includes(player["full-name"])
    );
    setMyTeamPlayers(myTeamPlayerData);
    console.log(myTeamFilteredPlayers);
  };
  const handleMyTeamLabel = (label) => {
    setMyTeamGradeTitle(label);
    console.log(label);
  };

  const handleSaveToMyTeam = async (e) => {
    e.preventDefault();
    const userID = currentUser.uid;
    const playerData = [...myTeamPlayers];
    const dataExcel = [...displayDataExcel];
    const colDefs = [...displayColDefs];
    if (myTeamGradeTitle.length !== 0) {
      for (let i = 0; i < dataExcel.length; i++) {
        // add the gameID key-value pair to the current object
        dataExcel[i].gameID = myTeamGradeTitle.join();
      }
      try {
        const batch = writeBatch(db);
        batch.set(
          doc(
            db,
            "Teams",
            userID,
            "EventData",
            myTeamGradeTitle + " PlayerData"
          ),
          {
            playerData,
            gradeLabel,
            userID,
            edititedAt: serverTimestamp(),
          }
        );
        batch.set(
          doc(db, "Teams", userID, "EventData", myTeamGradeTitle + " PlayData"),
          { colDefs, dataExcel, userID, edititedAt: serverTimestamp() }
        );
        await batch.commit();
        alert("Saved");
      } catch (error) {
        alert("error");
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Error Saving to myTeam",
          error,
          "top"
        );
      }
    } else {
      alert("Select a Title before saving!");
    }
  };

  const [PBtoggle, setPBToggle] = useState(true);

  //   const isMedScreen = useMediaQuery({ min-Width: 640 }); // Adjust the breakpoint as needed
  const isMedScreen = useMediaQuery({ query: "(min-width: 640px)" });

  const scrollableDivRef = HeaderScrollVisibility();

  const memoizedExpandedRowRender = useMemo(() => {
    return (record) => {
      if (playNumber !== record.Play) return;
      if (playerCols) {
        return (
          <div className="sticky left-2 w-[87vw] md:w-[92vw] h-fit">
            <Button
              className="z-30 text-black border-gray-500"
              onClick={handleAddSelected}
            >
              Add Selected Players
            </Button>
            <Table
              size="small"
              bordered
              scroll={{ x: true }}
              rowKey={(data) => data["name-play"]}
              rowClassName={() => "editable-row"}
              components={components}
              columns={memoizedPlayerCols}
              dataSource={displayData.filter(
                (player) => player.Play === record.Play
              )}
            />
          </div>
        );
      } else {
        setColumnFilterError("error");
        setTimeout(() => {
          setColumnFilterError("");
        }, 4000);
        return (
          <>
            <p className="text-center bg-red-300 font-bold border border-2-black p-auto p-1">
              Update 'Position Columns' above to add player data!
            </p>

            <Table
              bordered
              rowKey={(data) => data}
              columns={[]}
              dataSource={[]}
            />
          </>
        );
      }
    };
  }, [
    memoizedPlayerCols,
    handleAddSelected,
    displayData,
    setColumnFilterError,
  ]);

  return (
    <motion.div
      className="h-screen w-screen"
      initial={{ y: -500 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
    >
      <div
        ref={scrollableDivRef}
        className="overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar-hide snap-x px-1 h-screen pb-32"
      >
        <LogoPageHeader title={"Grade Sheet"} />

        <div className="w-full h-fit py-2 bg-slate-100 bg-opacity-20 rounded-lg rounded-t-none sm:rounded-t-lg">
          <div className="sticky z-40 top-[-30px] h-0 overflow-visible ">
            <motion.div
              animate={{ x: PBtoggle ? 0 : "105%" }}
              transition={{ ease: "easeInOut" }}
              className={`absolute right-[5%] h-fit w-[90%] md:w-[35%] lg:w-[40%] self-end p-6 pb-2 mt-14 sm:mb-0 shadow-slate-800 shadow-sm bg-neutral-200 rounded-lg`}
            >
              <Button
                className="absolute left-[-30px] top-[-12px] bg-slate-300 font-bold"
                onClick={() => setPBToggle(!PBtoggle)}
              >
                {PBtoggle ? "Hide" : "Show"}
              </Button>
              <Cascader
                className="w-full h-fit shadow-sm shadow-slate-800 rounded-lg"
                size="large"
                options={colOptions}
                allowClear={false}
                onChange={(title) => {
                  setColValue(title);
                  columnTitle(title);
                }}
                expandTrigger="hover"
                maxTagCount="responsive"
                placeholder="Select Position Columns"
                status={columnFilterError}
              />

              <PlayerCascader onPlayerValue={setPlayerButtons} />

              <PlayerTotals />
            </motion.div>
          </div>
          <div className="flex flex-col-reverse sm:flex-row h-fit w-full">
            <div
              className={`z-30 flex flex-col mx-auto transition-all ease-in-out ${!PBtoggle ? 'md:ml-[15vw] lg:ml-[36vw]' : 'md:ml-[15vw]'} py-1 px-2 w-[90%] ${
                showUpload ? "h-60" : "h-52"
              } h- sm:w-96 sm:h-64 bg-neutral-100 rounded-lg shadow-slate-800 shadow-sm`}
            >
              <AnimatePresence>
                {!showEdit && (
                  <motion.div
                    key="upload"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scaleY: 0 }}
                    className="flex flex-col mt-auto"
                  >
                    <motion.button
                      whileHover={{ scale: 0.9 }}
                      whileTap={{ scale: 1.1 }}
                      onClick={() =>
                        toggleShowUpload((showUpload) => !showUpload)
                      }
                      className="py-5 w-[90%] h-fit mx-auto bg-green-400 bg-opacity-50 hover:bg-green-200 rounded-md text-center font-bold shadow-slate-800 shadow-sm"
                    >
                      <div className="flex w-fit mx-auto">
                        <span>Upload Data</span>
                        <span className="scale-110 p-1">
                          {" "}
                          <GrDocumentExcel />{" "}
                        </span>
                      </div>
                    </motion.button>
                    <div className="flex flex-col justify-center align-middle">
                      <div className="flex flex-col w-full sm:mx-auto sm:scale-90">
                        {showUpload && (
                          <motion.div
                            initial={{ opacity: 0, scaleY: 0 }}
                            animate={{ opacity: 1, scaleY: 1 }}
                            exit={{ opacity: 0, scaleY: 0 }}
                            className="flex flex-col w-fit mx-auto bg-slate-300 shadow-slate-800 shadow-sm rounded-md sm:m-2 mt-0 py-4"
                          >
                            <input
                              className="mx-auto p-1 bg-white rounded-md"
                              type="file"
                              onChange={(e) => importExcel(e)}
                            />
                            <div className="z-40 mt-4 p-1 rounded-md bg-slate-400 flex flex-col">
                              <DataTitleCascader
                                updateTitle={updateGradeTitle}
                              />
                            </div>
                            <motion.button
                              whileHover={{ scale: 0.9 }}
                              whileTap={{ scale: 1.1 }}
                              className="p-1 mt-2 scale-90 mx-auto bg-black rounded-md w-fit text-white text-center font-bold"
                              onClick={() => {
                                savePlayData();
                              }}
                            >
                              Save to Database
                            </motion.button>
                          </motion.div>
                        )}
                      </div>
                    </div>
                  </motion.div>
                )}
                {!showUpload && (
                  <motion.div
                    key="edit"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, y: "-100%", z: 0, scale: 0 }}
                    className="flex flex-col mb-auto mt-6"
                  >
                    <motion.button
                      whileHover={{ scale: 0.9 }}
                      whileTap={{ scale: 1.1 }}
                      onClick={() => toggleShowEdit((showEdit) => !showEdit)}
                      className="w-[90%] py-5 mx-auto bg-green-400 bg-opacity-50 hover:bg-green-200 rounded-md text-center font-bold shadow-slate-800 shadow-sm"
                    >
                      <div className="flex w-fit mx-auto">
                        <span>Edit Data</span>
                        <span className="scale-110 p-1">
                          {" "}
                          <GrEdit />{" "}
                        </span>
                      </div>
                    </motion.button>
                    <div className="flex flex-col">
                      {showEdit && (
                        <motion.div
                          initial={{ opacity: 0, scaleY: 0 }}
                          animate={{ opacity: 1, scaleY: 1 }}
                          exit={{ opacity: 0, scaleY: 0 }}
                          transition={{ delay: 0.25 }}
                          className="my-auto pt-2 sm:pt-6"
                        >
                          <div>
                            <GSDataTitleCascader
                              updateTitle={updateFetchedGradeTitle}
                            />
                          </div>
                          <div className="flex justify-center align-middle h-fit w-full p-2 self-center rounded-lg bg-slate-200">
                            <motion.button
                              className="mx-2 font-bold my-1 w-fit px-2 self-center bg-white shadow-black shadow-sm p-1 rounded-md"
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={getData}
                            >
                              Update Data
                            </motion.button>
                            <motion.button
                              className="mx-2 font-bold my-1 w-fit px-2 self-center bg-white shadow-black shadow-sm p-1 rounded-md"
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={clearData}
                            >
                              Clear Data
                            </motion.button>
                          </div>
                        </motion.div>
                      )}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, scaleY: 0 }}
              animate={{ opacity: 1, scaleY: 1 }}
              exit={{ opacity: 0, scaleY: 0 }}
            >
              <Form>
                <div className="mr-auto ml-[4%] mt-2 w-fit h-fit text-white">
                  <span className="font-semibold">Status:</span> {firebaseSaved}
                </div>
                <div className="mx-auto w-[94vw] h-fit text-black border-2 border-slate-900 rounded-md overflow-hidden bg-gray-400">
                  <Table
                    className="relative w-[99.9%] mx-auto"
                    size="small"
                    columns={memoizedDisplayColDefs}
                    dataSource={displayDataExcel}
                    rowKey={(record) => record.Play}
                    scroll={{ x: "max-content" }}
                    expandable={{
                      expandRowByClick: true,
                      expandedRowKeys: playNumber ? [playNumber] : [],
                      onExpand: (expanded, record) => {
                        if (expanded) {
                          setPlayNumber(record.Play);
                        } else {
                          setPlayNumber(null);
                        }
                      },
                      rowExpandable: (record) => true,
                      expandedRowRender: memoizedExpandedRowRender,
                    }}
                  />
                </div>
                {showMyTeamModal && (
                  <motion.div
                    initial={{ opacity: 0, scaleY: 0, scaleX: 0 }}
                    animate={{ opacity: 1, scaleY: 1, scaleX: 1 }}
                    exit={{ opacity: 0, scaleY: 0, scaleX: 0 }}
                    className="flex flex-col flex-wrap w-screen"
                  >
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className="h-12 z-10 mx-auto shadow-lg w-[80%] sm:w-[40%] md:w-[30%] m-1 mt-12 rounded-md bg-slate-100 border-2 border-teal-500 hover:border-4 hover:border-teal-300 hover:cursor-pointer"
                      type="submit"
                      onClick={() => setShowAddToMyTeam(!showAddToMyTeam)}
                    >
                      Add Graded Player Data to{" "}
                      <span className="font-bold">myTeam</span>
                    </motion.button>

                    <AnimatePresence>
                      {showAddToMyTeam && (
                        <motion.div
                          initial={{ opacity: 0, scaleY: 0, y: -100 }}
                          animate={{ opacity: 1, scaleY: 1, y: 0 }}
                          exit={{ opacity: 0, scaleY: 0, y: -125 }}
                          className="flex flex-col mx-auto justify-center align-middle items-center bg-white p-2 rounded-lg"
                        >
                          <div className="w-72 sm:w-96">
                            <span className="rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                              Add players you want to save
                            </span>
                            <PlayerCascader
                              onPlayerValue={handleMyTeamPlayers}
                            />
                          </div>
                          <span className="w-fit self-start mb-[-20px] mt-4 rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                            Select an event label for myTeam
                          </span>
                          <div className="h-fit w-fit mt-6">
                            <MyTeamDataTitleCascader
                              updateTitle={handleMyTeamLabel}
                            />
                          </div>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="bg-slate-200 shadow-sm shadow-black hover:bg-green-400 rounded-xl p-2 mt-1"
                            onClick={(e) => handleSaveToMyTeam(e)}
                          >
                            Save to myTeam
                          </motion.button>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                )}
              </Form>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
}
