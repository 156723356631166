import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import {
  Cascader,
  Button,
  Input,
  Popconfirm,
  Space,
  Modal,
  Checkbox,
} from "antd";
import { db } from "../Firebase-config";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";

export function WCTitleCascader({ updateTitle, clearCallSheetData }) {
  const [dataTitle, setDataTitle] = useState([]);
  const [optionBar, setOptionBar] = useState(false);
  const [dataDocTitle, setDataDocTitle] = useState([]);
  const [cascaderDisabled, setCascaderDisabled] = useState(false);
  const [labelPlaceholder, setLabelPlaceholder] = useState([
    {
      value: "eg. Year",
      label: "eg. Year",
      children: [
        {
          value: "eg. Offense",
          label: "eg. Offense",
          children: [
            {
              value: "eg. 11p",
              label: "eg. 11p",
            },
          ],
        },
        {
          value: "eg. Defense",
          label: "eg. Defense",
        },
        {
          value: "eg. Special Teams",
          label: "eg. Special Teams",
        },
      ],
    },
  ]);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const barOpen = () => {
    setOptionBar(true);
    setUpload(null);
    setCascaderDisabled(true);
    console.log(dataTitle);
  };

  const handleCancel = () => {
    setCascaderDisabled(false);
    setOptionBar(false);
  };

  const barClosed = async () => {
    console.log(dataDocTitle);
    const batch = writeBatch(db);
    setCascaderDisabled(false);
    setOptionBar(false);
    console.log(upload);
    if (upload) {
      const docRef = doc(db, "Teams", selectedPath, "DataLabels", "WCLabels");
      batch.set(docRef, upload);
      // const dataDocRef1 = doc(
      //   db,
      //   "Teams",
      //   selectedPath,
      //   "DepthChart",
      //   dataDocTitle
      // );
      // if (dataDocRef1) {
      //   batch.delete(dataDocRef1);
      // }
      try {
        await batch.commit();
        openNotificationWithIcon("success", "Labels Updated", "", "top", "", 2);
        console.log("Batch operation complete");
        // if (includePBDatabase) {
        //   clearCallSheetData(true);
        // } else {
        //   clearCallSheetData(false);
        // }
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Failed to Update",
          error,
          "top",
          "",
          2
        );
      }
    }
    console.log(upload);
  };

  useEffect(() => {
    const getTitleDoc = async () => {
      const docRef = doc(db, "Teams", selectedPath, "DataLabels", "WCLabels");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().dataTitle !== undefined) {
        setDataTitle(docSnap.data().dataTitle);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setDataTitle(labelPlaceholder);
      }
    };
    getTitleDoc();
  }, [selectedPath]);

  const [newOption, setNewOption] = useState([]);
  const [upload, setUpload] = useState();
  const [cascaderValue, setCascaderValue] = useState();

  const addOption = async () => {
    const initialTitle = () => {
      if (dataTitle === labelPlaceholder) {
        return [];
      } else {
        return dataTitle;
      }
    };
    let parent = initialTitle();

    if (cascaderValue !== undefined) {
      // Find the parent node of the new option
      for (let i = 0; i < cascaderValue.length; i++) {
        let node = parent.find((option) => option.value === cascaderValue[i]);
        if (node.children) {
          parent = node.children;
        } else {
          node.children = [];
          parent = node.children;
        }
      }
    }

    // Check for duplicates
    if (
      parent !== undefined &&
      parent.some((option) => option.value === newOption)
    ) {
      openNotificationWithIcon(
        "error",
        "Duplicate Label!",
        "Choose a different Label Name",
        "top",
        "",
        2
      );
      return;
    }

    // Add the new option
    parent.push({
      value: newOption,
      label: newOption,
      children: [],
    });
    openNotificationWithIcon("success", "Option Added", "", "top", "", 2);

    setUpload({ dataTitle });
    setDataTitle([...dataTitle]);
  };

  const handleDeleteOption = async () => {
    let parent = dataTitle;

    if (cascaderValue !== undefined) {
      // Find the parent node of the new option
      for (let i = 0; i < cascaderValue.length; i++) {
        let node = parent.find((option) => option.value === cascaderValue[i]);
        if (node.children) {
          parent = node.children;
        } else {
          node.children = [];
          parent = node.children;
        }
      }
    }

    // Check for duplicates
    if (
      parent !== undefined &&
      parent.some((option) => option.value === newOption)
    ) {
      let node = parent.find((option) => option.value === newOption);

      // Check for multiple children
      if (node.children && node.children.length > 0) {
        openNotificationWithIcon(
          "error",
          "Cannot Delete Group",
          "Try Deleting all events under the grouping first.",
          "top",
          "",
          2
        );
        return;
      }

      const nonFalsyCascaderValue = !cascaderValue
        ? node.value
        : cascaderValue + "," + node.value;

      parent.splice(parent.indexOf(node), 1);
      setDataDocTitle(nonFalsyCascaderValue);
      setUpload({ dataTitle });
      setDataTitle([...dataTitle]);
      console.log(dataTitle);
      openNotificationWithIcon(
        "success",
        "Label & Data Removed",
        "close the menu to save changes",
        "top",
        "",
        2
      );
    } else {
      openNotificationWithIcon(
        "error",
        "Label Does Not Exist",
        "Make sure spelling and punctuation is correct and try again.",
        "top",
        "",
        2
      );
      return;
    }
  };

  const labelInputDefault = () => {
    if (dataTitle == labelPlaceholder) {
      return [];
    } else {
      return dataTitle;
    }
  };

  const [includePBDatabase, setIncludePBDatabase] = useState(true);

  return (
    <div className="relative h-fit">
      <div className="flex w-full h-fit flex-col rounded-lg">
        <div className="flex flex-row">
          <Cascader
            className="h-full w-full"
            size="large"
            options={dataTitle}
            disabled={cascaderDisabled}
            onChange={(e) => updateTitle(e)}
            changeOnSelect
            onClear={(e) => updateTitle(e)}
            maxTagCount="responsive"
            placeholder="Select Call Sheet"
          />

          <Button
            className="h-8 w-fit ml-[-10px]"
            type="secondary"
            onClick={() => (optionBar ? barClosed() : barOpen())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-white hover:text-yellow-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Button>
        </div>

        <Modal
          className="h-screen w-fit flex pt-[8%]"
          centered
          width={1100}
          open={optionBar}
          onOk={barClosed}
          onCancel={handleCancel}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
            <Button className="" onClick={barClosed}>
              Update Labels
            </Button>,
          ]}
        >
          <Space.Compact className="w-full">
            <div className="flex flex-col w-[80%] mx-auto">
              <div className="flex text-center w-[80%]">
                <div className="font-semibold ml-2">
                  Select All Labels that Apply
                </div>
                <div className="font-semibold mx-auto">
                  New Call Sheet Title
                </div>
              </div>
              <Input
                className="w-full"
                addonBefore={
                  <Cascader
                    options={labelInputDefault()}
                    value={cascaderValue}
                    changeOnSelect
                    onChange={(e) => setCascaderValue(e)}
                    placeholder="Year / ODK / vs Team"
                  />
                }
                placeholder="vs Florida"
                onChange={(e) => setNewOption(e.target.value)}
              />
              {/* <Checkbox
                className="w-fit mx-auto mt-4 mb-8"
                checked={includePBDatabase}
                onChange={() => setIncludePBDatabase(!includePBDatabase)}
              >
                <div className="ml-2 flex flex-col">
                  <div className="mx-auto">Include Playbook Database? </div>
                  <div>{"( Formations / Motions / Runs / Passes )"}</div>
                </div>
              </Checkbox> */}
              <div className="flex w-fit mx-auto gap-3 mt-4">
                <Popconfirm
                  okType="danger"
                  title="Delete this label and ALL associated DATA?"
                  onConfirm={handleDeleteOption}
                >
                  <Button className="text-black" danger type="dashed">
                    Remove Label & Data
                  </Button>
                </Popconfirm>
                <Button className="w-fit" type="primary" onClick={addOption}>
                  Add Call Sheet Label
                </Button>
              </div>
            </div>
          </Space.Compact>
        </Modal>
      </div>
    </div>
  );
}
